.container{
    margin-top: 50px;
}

.heading{
    font-size: 3em;
}

.form{
    margin: auto;
    width: 80%;
}